import { datadogLogs } from '@datadog/browser-logs';
import { ServiceViewModel } from 'external-apis/src/types/port';
import { useFlag } from 'feature-toggle';
import { difference } from 'lodash';
import { useGetRecalls } from '../../_api/http/recalls';

export function useRecall(vin: string, services: ServiceViewModel[]) {
    const recallCodes = ['48R7', '48R8', '48S2', '93V2', '93U9'];
    const recalls = useGetRecalls(vin);

    const showRecall = useFlag('show-recall');

    if (recalls.isPending) {
        return {
            isLoading: recalls.isPending,
            services: undefined,
        };
    }

    if (!showRecall || recalls.isError) {
        if (recalls.isError) {
            datadogLogs.logger.error('Error when fetching recalls');
        }
        return {
            isLoading: false,
            services: services.filter(
                (x) => !recallCodes.some((y) => x.materialNumber === y)
            ),
            showInfoBox: false,
        };
    }

    const servicesToBeFiltered = difference(
        recallCodes,
        recalls.data.map((x) => x.recallCode)
    );

    return {
        isLoading: false,
        services: services.filter(
            (x) => !servicesToBeFiltered.some((y) => x.materialNumber === y)
        ),
        showInfoBox:
            recalls.data.length > 0 &&
            !services.some((x) =>
                recallCodes.some((y) => x.materialNumber === y)
            ),
    };
}
