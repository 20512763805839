import {
    CheckboxCard,
    Column,
    DetailText,
    FormProvider,
} from '@gnist/design-system';
import { LocalFormState } from '@gnist/design-system/utilities/forms/useLocalFormState.js';
import { isSameDay } from 'date-fns';
import {
    ReservedTimeSlotViewModel,
    ServiceViewModel,
} from 'external-apis/src/types/port';
import { useFlag } from 'feature-toggle';
import { pipe } from 'fp-ts/lib/function';
import { useEffect, useRef } from 'react';
import { useGetServices } from '../../_api/http/services';
import { useGetVehicle } from '../../_api/http/vehicle';
import {
    BilholdNextButton,
    BilholdSkipButton,
} from '../../components/bilhold/BilholdNextButton';
import { BilholdInnerViewLayout } from '../../components/bilhold/BilholdView';
import { QueryError } from '../../components/QueryError';
import { ExpandableServiceDescription } from '../../components/services/ExpandableServiceDescription';

import {
    LanguageContextType,
    useLanguageContext,
} from '../../lib/languages/languageContext';
import { ChooseDealerData } from '../choose-dealer/ChooseDealerSection';
import { parseServicePrices, Price } from '../shared/prices/Price';
import { toSelectedService } from '../shared/toSelectedService';
import { DealerFacilities } from './DealerFacilities';
import { TransportServicesLoader } from './TransportServicesLoader';
import { TransportServicesState } from './TransportServicesSection';

interface TransportServicesEditProps {
    regNr: string;
    reservedTimeSlot?: ReservedTimeSlotViewModel;
    selectedDealer: ChooseDealerData;
    setSectionState: (x: TransportServicesState) => void;
    servicesForm: LocalFormState<TrspServicesForm>;
}

export const IS_WAITING_AT_DEALER = 'custom-waiting-at-dealer';

// Waiting option is not an actual service. We create a
// synthetic service which response we store as isWaiting
function useGetWaitingOptionService({
    reservedTimeSlot,
    lc,
    servicesForm,
}: {
    reservedTimeSlot?: ReservedTimeSlotViewModel;
    lc: LanguageContextType;
    servicesForm: LocalFormState<TrspServicesForm>;
}) {
    const waitingOption: ServiceViewModel = {
        adapterId: '',
        dealerSpecificInformation: [],
        description: '',
        serviceType: 'Waiting',
        id: IS_WAITING_AT_DEALER,
        name: lc.transportServices.wait_at_dealer,
        bookingType: 'None',
    };
    const setValue = servicesForm.setValue('selectedIds');
    const selectedIds = servicesForm.state.raw.selectedIds;

    const ref = useRef(false);

    const startDatetime = new Date(
        reservedTimeSlot?.startTime ?? new Date()
    ).toDateString();
    const endDatetime = new Date(
        reservedTimeSlot?.estimatedFinished ?? new Date()
    ).toDateString();

    useEffect(() => {
        if (
            !ref.current &&
            !isSameDay(new Date(startDatetime), new Date(endDatetime))
        ) {
            const serviceIds = servicesForm.state.raw.selectedIds.filter(
                (id) => id !== IS_WAITING_AT_DEALER
            );
            setValue([...serviceIds]);
            ref.current = true;
        }
    }, [endDatetime, selectedIds, servicesForm, setValue, startDatetime]);

    if (isSameDay(new Date(startDatetime), new Date(endDatetime))) {
        return waitingOption;
    }
}

export interface TrspServicesForm {
    selectedIds: string[];
}

export function TransportServicesEdit({
    regNr,
    reservedTimeSlot,
    selectedDealer,
    setSectionState,
    servicesForm,
}: TransportServicesEditProps) {
    const [lc] = useLanguageContext();

    const vehicle = useGetVehicle(regNr);

    const waitingOption = useGetWaitingOptionService({
        reservedTimeSlot,
        lc,
        servicesForm,
    });

    const serviceResponse = useGetServices({
        vin: vehicle.data?.vin,
        dealerNumbers: [selectedDealer.dealerNumber],
    });

    const showWaitingServicesInSummary = useFlag(
        'show-waiting-services-in-summary'
    );

    if (vehicle.isPending || serviceResponse.isPending) {
        return <TransportServicesLoader />;
    }

    if (vehicle.isError || serviceResponse.isError) {
        return (
            <QueryError
                isError
                error={serviceResponse.error ?? vehicle.error}
            />
        );
    }

    const multichoiceOptions = pipe(
        [...serviceResponse.data.Transport, ...serviceResponse.data.Delivery],
        (x) => (waitingOption ? [waitingOption, ...x] : x)
    ).map((x) => ({
        value: x.id,
        label: x.name,
        item: x,
        description: x.description,
    }));

    const showWaitingOptions =
        0 < serviceResponse.data.Waiting.length &&
        !showWaitingServicesInSummary &&
        waitingOption;

    const inputProps = servicesForm.inputProps('selectedIds');
    const { value: selectedValues, validity } = inputProps;
    const cardGroupValues = Object.fromEntries(
        selectedValues.map((x) => [x, true])
    );
    const services = [
        ...serviceResponse.data.Transport,
        ...serviceResponse.data.Delivery,
    ];

    return (
        <FormProvider
            id={'transportserviceslist-formprovider'}
            form={servicesForm}
            hideNecessityText={true}
            onSubmit={(event) => {
                const selectedIds = event.selectedIds.filter(
                    (x) => x !== IS_WAITING_AT_DEALER
                );
                const selectedServices = services
                    .filter((x) => selectedIds.includes(x.id))
                    .map((x) => toSelectedService(x));
                setSectionState({
                    viewMode: 'done',
                    data: {
                        selectedServices,
                        isWaiting:
                            event.selectedIds.includes(IS_WAITING_AT_DEALER),
                    },
                });
            }}
        >
            {showWaitingOptions && (
                <DealerFacilities
                    waitingServices={serviceResponse.data.Waiting}
                />
            )}
            <BilholdInnerViewLayout>
                <Column gap="base" id="transport-services-list">
                    {multichoiceOptions.map((option) => {
                        const isSelected = cardGroupValues[option.value];

                        return (
                            <CheckboxCard
                                density="compact"
                                key={option.item.id}
                                label={option.label}
                                value={isSelected}
                                validity={validity}
                                onChange={(event) => {
                                    const newValue = {
                                        ...cardGroupValues,
                                        [option.item.id]: event.target.checked,
                                    };
                                    const newSelectedValues = Object.entries(
                                        newValue
                                    )
                                        .filter(([_key, val]) => val)
                                        .map(([key]) => key);
                                    inputProps.setValue(newSelectedValues);
                                }}
                                description={
                                    <Column gap="xs">
                                        {option.description && (
                                            <ExpandableServiceDescription
                                                description={option.description}
                                                forceExpanded={isSelected}
                                            />
                                        )}
                                        {option.item.id !==
                                            IS_WAITING_AT_DEALER && (
                                            <Price
                                                values={parseServicePrices(
                                                    option.item,
                                                    selectedDealer.dealerNumber
                                                )}
                                            />
                                        )}
                                    </Column>
                                }
                            />
                        );
                    })}
                </Column>
                <DetailText>{lc.prices.disclaimer_price}</DetailText>
                {servicesForm.state.raw.selectedIds.length > 0 ? (
                    <BilholdNextButton />
                ) : (
                    <BilholdSkipButton />
                )}
            </BilholdInnerViewLayout>
        </FormProvider>
    );
}
