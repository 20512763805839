import {
    maxLength,
    validation,
    ValidationStep,
    ValidationStepResult,
} from '@gnist/design-system/utilities/validation';
import { ServiceViewModel } from 'external-apis/src/types/port';
import { Services } from '../../_api/http/services';
import { StdServicesForm } from '../../sections/standard-services/useSectionForm';
import { IS_WAITING_AT_DEALER } from '../../sections/transport-services/TransportServicesEdit';
import { useLanguageContext } from '../languages/languageContext';

function oneServiceChecked(
    list: string[],
    message: string
): ValidationStepResult {
    return {
        isValid: list.length > 0,
        errorMessage: message,
    };
}
export function useValidStandardServices(
    availableServices: Services | undefined,
    additionalSteps: ValidationStep<string[], StdServicesForm>[]
) {
    const [lc] = useLanguageContext();
    return validation<string[], StdServicesForm>({
        steps: [
            (_, allValues) =>
                hasValidRecommendedServiceInput(
                    allValues,
                    availableServices,
                    lc.errors.recommended_service_mileage_validation
                ),
            (list: string[]) =>
                oneServiceChecked(
                    list,
                    lc.errors.multichoice_services_validation
                ),
            (list: string[], allValues) => ({
                isValid: hasRequiredComment(list, allValues.additionalInfo, [
                    ...(availableServices?.Standard ?? []),
                    ...(availableServices?.Recommended ?? []),
                ]),
                errorMessage: lc.errors.other_service_comment_validation,
            }),
            ...additionalSteps,
        ],
    });
}

export function useValidServiceAdditionalInfo(
    availableServices: Services | undefined
) {
    const [lc] = useLanguageContext();
    return validation<StdServicesForm['additionalInfo'], StdServicesForm>({
        steps: [
            (list, allValues) => {
                return {
                    isValid: hasRequiredComment(allValues.selectedIds, list, [
                        ...(availableServices?.Standard ?? []),
                        ...(availableServices?.Recommended ?? []),
                        ...(availableServices?.Delivery ?? []),
                        ...(availableServices?.Transport ?? []),
                        ...(availableServices?.Additional ?? []),
                    ]),
                    errorMessage:
                        lc.errors.other_service_comment_validation_text_field,
                };
            },
        ],
    });
}

function hasValidRecommendedServiceInput(
    allValues: StdServicesForm,
    availableServices: Services | undefined,
    message: string
) {
    const hasWrittenMileage = allValues.recommendedService.mileage.length > 0;
    const hasFetchedRecommendedServices = availableServices?.Recommended
        ? availableServices.Recommended.length > 0
        : false;

    return {
        isValid:
            !allValues.recommendedService.open ||
            (!hasWrittenMileage && !hasFetchedRecommendedServices) ||
            (hasWrittenMileage && hasFetchedRecommendedServices),
        errorMessage: message,
    };
}

function hasRequiredComment(
    selectedServices: string[],
    serviceAdditionalInfos: StdServicesForm['additionalInfo'],
    availableServices: ServiceViewModel[]
) {
    return selectedServices.every((selectedServiceId) => {
        const serviceModel = availableServices.find(
            (x) => x.id === selectedServiceId
        );
        const commentRequired = serviceModel?.additionalInfo?.some(
            (additionalInfo) =>
                additionalInfo.isRequired &&
                additionalInfo.infoType === 'Comment'
        );
        if (commentRequired) {
            const comment = serviceAdditionalInfos.find(
                (x) => x.serviceId === selectedServiceId
            )?.comment;
            return comment && comment.length > 0;
        }
        return true;
    });
}

// If 'I can wait at the workshop' option is checked, no other option can be checked at the same time
function waitAtWorkshopChecked(
    list: string[],
    message: string
): ValidationStepResult {
    const isValid = !(list.includes(IS_WAITING_AT_DEALER) && list.length > 1);
    return {
        isValid,
        errorMessage: message,
    };
}

export function getTransportServicesValidator(message: string) {
    return validation({
        steps: [(list: string[]) => waitAtWorkshopChecked(list, message)],
    });
}

const minLength =
    (length: number) =>
    (value: string): ValidationStepResult => ({
        isValid: value.length >= length,
        errorMessage: '',
    });

export function useValidLength(
    length: number,
    availableServices: Services | undefined
) {
    const [lc] = useLanguageContext();
    return validation<string, StdServicesForm>({
        steps: [maxLength(length), minLength(1)],
        necessity: (values) => ({
            isRequired: values.selectedIds.some(
                (selectedId) =>
                    availableServices?.Standard.find((x) => x.id === selectedId)
                        ?.category === 'GlassDamage'
            ),
            missingMessage: lc.errors.glass_service_location_validation,
        }),
    });
}
