import { CheckboxCard, Column, FormProvider } from '@gnist/design-system';
import { LocalFormState } from '@gnist/design-system/utilities/forms/useLocalFormState.js';
import { isSameDay } from 'date-fns';
import { ReservedTimeSlotViewModel } from 'external-apis/src/types/port';
import { useGetServices } from '../../_api/http/services';
import { useGetVehicle } from '../../_api/http/vehicle';
import { QueryError } from '../../components/QueryError';
import {
    BilholdNextButton,
    BilholdSkipButton,
} from '../../components/bilhold/BilholdNextButton';
import { BilholdInnerViewLayout } from '../../components/bilhold/BilholdView';
import { ExpandableServiceDescription } from '../../components/services/ExpandableServiceDescription';
import { ChooseDealerData } from '../choose-dealer/ChooseDealerSection';
import { parseServicePrices, Price } from '../shared/prices/Price';
import { toSelectedService } from '../shared/toSelectedService';
import { AdditionalServicesLoader } from './AdditionalServicesLoader';
import { AdditionalServicesState } from './AdditionalServicesSection';

interface AdditionalServicesEditProps {
    regNr: string;
    selectedDealer: ChooseDealerData;
    reservedTimeSlot?: ReservedTimeSlotViewModel;
    setSectionState: (x: AdditionalServicesState) => void;
    servicesForm: LocalFormState<AddServicesForm>;
}

export type AddServicesForm = {
    selectedIds: string[];
};

export function AdditionalServicesEdit({
    regNr,
    selectedDealer,
    setSectionState,
    servicesForm,
    reservedTimeSlot,
}: AdditionalServicesEditProps) {
    const vehicle = useGetVehicle(regNr);
    const serviceResponse = useGetServices({
        vin: vehicle.data?.vin,
        dealerNumbers: [selectedDealer.dealerNumber],
    });

    if (vehicle.isPending || serviceResponse.isPending) {
        return <AdditionalServicesLoader />;
    }

    if (vehicle.isError || serviceResponse.isError) {
        return (
            <QueryError
                isError
                error={vehicle.error ?? serviceResponse.error}
            />
        );
    }

    const startDatetime = new Date(reservedTimeSlot?.startTime ?? new Date());
    const endDatetime = new Date(
        reservedTimeSlot?.estimatedFinished ?? new Date()
    );

    const filteredServices = isSameDay(startDatetime, endDatetime)
        ? serviceResponse.data.Additional.filter(
              (service) => !service.isOvernight
          )
        : serviceResponse.data.Additional;

    const multichoiceOptions = filteredServices.map((service) => {
        return {
            value: service.id,
            label: service.name,
            description: service.description,
            item: service,
        };
    });

    const inputProps = servicesForm.inputProps('selectedIds');
    const { value: selectedValues, validity } = inputProps;
    const cardGroupValues = Object.fromEntries(
        selectedValues.map((x) => [x, true])
    );

    return (
        <FormProvider
            id={'additionalserviceslist-formprovider'}
            form={servicesForm}
            hideNecessityText={true}
            onSubmit={(event) => {
                const selectedIds = event.selectedIds;
                const selectedServices = serviceResponse.data.Additional.filter(
                    (x) => selectedIds.includes(x.id)
                ).map((x) => toSelectedService(x));

                setSectionState({
                    viewMode: 'done',
                    data: {
                        selectedServices,
                    },
                });
            }}
        >
            <BilholdInnerViewLayout>
                <Column id="additional-services-list" gap="base">
                    {multichoiceOptions.map((option) => {
                        const isSelected = cardGroupValues[option.value];
                        return (
                            <CheckboxCard
                                key={option.item.id}
                                density="compact"
                                validity={validity}
                                onChange={(event) => {
                                    const newValue = {
                                        ...cardGroupValues,
                                        [option.item.id]: event.target.checked,
                                    };
                                    const newSelectedValues = Object.entries(
                                        newValue
                                    )
                                        .filter(([_key, val]) => val)
                                        .map(([key]) => key);
                                    inputProps.setValue(newSelectedValues);
                                }}
                                value={isSelected}
                                label={option.label}
                                description={
                                    <Column gap="xs">
                                        {option.description && (
                                            <ExpandableServiceDescription
                                                description={option.description}
                                                forceExpanded={isSelected}
                                            />
                                        )}
                                        <Price
                                            values={parseServicePrices(
                                                option.item,
                                                selectedDealer.dealerNumber
                                            )}
                                        />
                                    </Column>
                                }
                            />
                        );
                    })}
                </Column>
                {servicesForm.state.raw.selectedIds.length > 0 ? (
                    <BilholdNextButton />
                ) : (
                    <BilholdSkipButton />
                )}
            </BilholdInnerViewLayout>
        </FormProvider>
    );
}
